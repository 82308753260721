<template>
  <div class="sign">
    <div class="tc">
      <el-image
        :src="logo"
        class="image cursor"
        @click="$router.push('/')"
      ></el-image>
    </div>
    <el-tabs v-model="activeName" class="tabs">
      <el-tab-pane label="验证码登录" name="code" class="codeLogin">
      </el-tab-pane>
      <el-tab-pane label="密码登录" name="phone" class="passwordLogin">
      </el-tab-pane>
    </el-tabs>
    <div class="loginContent">
      <div class="emailSign" style="margin-top: 20px">
        <sign
            :signType="activeName"
            @successChange="loginSuccess"
            :expires="expires"
            @getLoading="btnLoadingChange"
        >
          <el-button
              slot="signBtn"
              type="primary"
              class="loginBut"
              :loading="btnLoading"
          >{{ $t("sign") }}</el-button
          >
        </sign>
      </div>
      <div class="clearfix">
        <div class="forgetPassword fl">
          <router-link to="/forgetPassword"
            >{{ $t("forgetPassword") }}？
          </router-link>
        </div>
        <div class="toReigster fr">
          <a @click="toRegister" class="registerText cursor">
            {{ $t("immedRegister") }}
          </a>
        </div>
      </div>
      <div class="privacy">
        <div class="">
          <router-link to="/privacypolicy" target="_blank">
            登录视为您已同意 <span class="green">用户协议与隐私条款</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sign from "~ymp/components/login/sign";
import { getAilaDomainName } from "~/baseUtils";
export default {
  components: { sign },
  data() {
    return {
      activeName: "code",
      btnLoading: false,
      emailLoading: false,
      logo: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/logo.png",
      expires: 7,
    };
  },
  mounted() {
  },
  methods: {
    btnLoadingChange(val) {
      this.btnLoading = val;
    },
    async loginSuccess(val) {
      this.btnLoading = false;
      this.$router.push("/");
    },
    toRegister() {
      this.$router.push("/signUp");
    },
  },
};
</script>
<style lang="less" scoped>
.tabs {
  display: flex;
  justify-content: center;
  margin-top: 10px;

}

.sign {
  width: 470px;
  padding: 0 40px;
  .image {
    width: 185px;
  }
  .subtitle {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    min-height: 30px;
    margin: 30px 0;
    line-height: 25px;
  }
  .loginContent {
     //margin-top: 30px;
    .loginBut {
      width: 390px;
      display: block;
      height: 40px;
      margin: 24px auto 12px;
      border: 0 none;
      font-size: 16px;
      background: #91BE42;
    }
  }
  .emailSign {
    margin-top: 10px;
  }
  .forgetPassword {
    a {
      color: #999999;
    }
  }
  .otherLogin {
    margin-top: 30px;
    padding-top: 5px;
  }
  .otherTips {
    color: #999;
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      background: #ececec;
    }
    .txtTips {
      background: #ffffff;
      padding: 0 20px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: -10px;
    }
  }
  .iconList {
    margin-top: 20px;
    .wxImg {
      width: 35px;
      height: auto;
    }
    img {
      height: 20px;
      margin: 0 8px;
      cursor: pointer;
    }
  }
}
/deep/ .el-checkbox {
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #91BE42;
  }
  &.is-checked {
    .el-checkbox__inner {
      background-color: #91BE42;
      border-color: #91BE42;
    }
  }
}
.tabs /deep/ .el-tabs__header {
  .el-tabs__active-bar {
    background-color: #91BE42;
  }
  .el-tabs__item {
    font-size: 16px;
    color: #7d8695;
    &:hover {
      color: #91BE42;
    }
    &.is-active {
      color: #91BE42;
    }
  }
}
.registerText {
  color: #91BE42;
}
.privacy{
  padding: 20px 0;
  .green{
    color: #91BE42;
  }
}
</style>