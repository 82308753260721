<template>
  <div>
    <div class="sign">
      <!-- 邮箱 -->
      <el-form
          v-if="signType == 'email'"
          :label-position="'top'"
          label-width="80px"
          :model="emailForm"
          ref="emailForm"
          :rules="emailRules"
      >
        <el-form-item prop="email">
          <el-input
              v-model="emailForm.email"
              size="medium"
              :placeholder="$t('enterEmail')"
              :prefix-icon="prefixIcon.email"
              nowritten
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              :prefix-icon="prefixIcon.emailPass"
              v-model="emailForm.password"
              type="password"
              autocomplete="new-password"
              size="medium"
              nowritten
              :placeholder="$t('enterPassword')"
              :show-password="true"
              @keyup.enter.native="login"
          ></el-input>
          <div class="custom_error_tip" v-show="cetshow">
            {{ $t("notActiveTip1") }}
            <a @click="getNewActiveLink">{{ $t("clickHere") }}</a>
            {{ $t("notActiveTip2") }}
          </div>
          <div class="custom_error_tip" v-show="emailErrorShow">
            {{ $t("invalidEmailAccount") }}
          </div>
          <div class="custom_error_tip" v-show="emailDisabledShow">
            {{ $t("accountDisabledTip") }}
          </div>
          <div class="custom_error_tip" v-show="emailErrorLogion">
            {{ $t("emailNotReg") }}
          </div>
        </el-form-item>
      </el-form>
      <!-- 电话 -->
      <el-form
          v-if="signType == 'phone'"
          :label-position="'top'"
          label-width="80px"
          :model="phoneForm"
          ref="phoneForm"
          :rules="phoneRules"
      >
        <el-form-item prop="phone">
          <el-input
              v-model="phoneForm.phone"
              size="medium"
              type="number"
              class="input-with-select"
              :placeholder="$t('phone')"
          >
            <el-select
                v-model="phoneForm.areaCode"
                slot="prepend"
                popper-class="registerAreaCodeList"
                filterable
                :filter-method="areaCodeFilter"
            >
              <el-option
                  v-for="(item, index) in areaCodeList"
                  :key="item.area_code + index + ''"
                  :value="item.area_code"
                  :title="item.country | areacodeTitleFormat(LOCALE)"
              >
                <p class="area_code">{{ item.area_code }}</p>
                <p class="country textOverflow">
                  {{ item.country | areacodeAddressFormat(LOCALE) }}
                </p>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              v-model="phoneForm.password"
              type="password"
              nowritten
              autocomplete="new-password"
              size="medium"
              :placeholder="$t('enterPassword')"
              :show-password="true"
              @keyup.enter.native="login"
          ></el-input>
          <div class="custom_error_tip" v-show="phoneErrorShow">
            {{ $t("invalidphoneAccount") }}
          </div>
          <div class="custom_error_tip" v-show="phoneDisabledShow">
            {{ $t("accountDisabledTip1") }}
          </div>
          <div class="custom_error_tip" v-show="phoneErrorLogion">
            {{ $t("telephoneNotReg") }}
          </div>
        </el-form-item>
      </el-form>
      <el-form
          v-if="signType == 'code'"
          :label-position="'top'"
          :model="codeForm"
          ref="codeForm"
          :rules="phoneRules"
      >
        <el-form-item label prop="phone">
          <el-input
              placeholder="请输入手机号"
              v-model="codeForm.phone"
              class="input-with-select"
              type="number"
          >
            <el-select
                v-model="codeForm.areaCode"
                slot="prepend"
                popper-class="registerAreaCodeList"
                filterable
                :placeholder="$t('pleaseSelect')"
                :filter-method="areaCodeFilter"
            >
              <el-option
                  v-for="(item, index) in areaCodeList"
                  :key="item.area_code + index + ''"
                  :value="item.area_code"
                  :title="item.country | areacodeTitleFormat(LOCALE)"
              >
                <p class="area_code">{{ item.area_code }}</p>
                <p class="country textOverflow">
                  {{ item.country | areacodeAddressFormat(LOCALE) }}
                </p>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
              style="width: 265px"
              placeholder="请输入验证码"
              type="text"
              v-model="codeForm.code"
              autocomplete="off"
              nowritten
          ></el-input>
          <el-button
              class="fr getCodeBtn"
              @click="getCode"
              :disabled="codeBtnDisabled"
          >{{ codeText }}</el-button
          >
          <div class="custom_error_tip" v-show="codeErrorShow">
            {{ $t("invalidphoneAccount") }}
          </div>
          <div class="custom_error_tip" v-show="codeErrorShow2">
            {{ $t("errorVerifyCode") }}
          </div>
          <div class="custom_error_tip" v-show="codeErrorShow3">
            请输入验证码
          </div>
          <div class="custom_error_tip" v-show="codeDisabledShow">
            {{ $t("accountDisabledTip") }}
          </div>
          <div class="custom_error_tip" v-show="codeErrorLogion">
            账号未注册
          </div>
        </el-form-item>
      </el-form>
      <!-- 七日内自动登录 -->
      <div v-if="expires">
        <el-checkbox v-model="sevenChecked">{{
            $t("loginWith", { expires: expires })
          }}</el-checkbox>
      </div>
      <div @click="login" class="signBtn">
        <slot name="signBtn"></slot>
      </div>
    </div>
  </div>
</template>
<script>
/**参数:
 *    1.signType:email/phone ;  email是邮箱验证，phone是电话验证
 * 事件:
 *1、successChange:登录成功反射的方法
 *2、getLoading:点击登录在请求接口之前返回的方法（一般用来开启插槽按钮的loading）
 * 插槽:
 *   signBtn 登录按钮插槽（在父组件不用再写登录接口，只需要写样式文字和loading）
 *
 *
 */
import { areaCodeList } from "~/baseUtils/AddressConfig";
import {
  validateTelephone,
  validateEmailOrTelephone,
  validateEmail,
} from "~/baseUtils/validate";

export default {
  props: {
    signType: {
      type: String,
      default: "code",
    },
    expires: {
      //传几就是几日登录
      type: Number,
      default: 0,
    },
    prefixIcon: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailorPhoneLabel: this.$t("emailOrPhone"),
      passwordLabel: this.$t("password"),
      emailForm: {
        email: "",
        password: "",
      },
      phoneForm: {
        phone: "",
        password: "",
        areaCode: "+86",
      },
      sevenChecked: false,
      cetshow: false,
      phoneErrorLogion: false,
      emailErrorLogion: false,
      codeErrorLogion: false,
      emailErrorShow: false,
      phoneErrorShow: false,
      codeErrorShow: false,
      codeErrorShow2: false,
      codeErrorShow3: false,
      emailDisabledShow: false,
      phoneDisabledShow: false,
      codeDisabledShow: false,
      areaCodeList: areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      emailRules: {
        email: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateEmail(value)) {
              this.codeBtnDisabled = true;
              callback(new Error(this.$t("wrongEmailFormat")));
            } else {
            }
          },
          trigger: "blur",
        },
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      phoneRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback(new Error(this.$t("wrongTelephoneFormat")));
            } else {
            }
          },
          trigger: "blur",
        },
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      codeForm: {
        phone: "",
        code: "",
        areaCode: "+86",
      },
      codeBtnDisabled: false,
      codeText: "获取验证码",
      notRegisterShow: false
    };
  },
  methods: {
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
            .filter((item) => {
              return item.area_code.indexOf(value) == 1;
            })
            .sort((a, b) => {
              return parseInt(a.area_code) - parseInt(b.area_code);
            });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    login() {
      let _params = {
            login_type: 1,
          },
          params = {};

      if (this.sevenChecked){
        _params.expires = this.expires;
      } 
      if (this.signType == "phone") {
        params = Object.assign(_params, { password: this.phoneForm.password });
        params.area_code = this.phoneForm.areaCode.replace("+", "")
        params.mobile = this.phoneForm.phone
      } else if(this.signType == "code"){
        if (this.codeForm.code == '') {
          return this.codeErrorShow3 = true
        } else {
          this.codeErrorShow3 = false
        }
        params.login_type = 2
        params.area_code = this.codeForm.areaCode.replace("+", "")
        params.mobile = this.codeForm.phone
        params.verification_code = this.codeForm.code
        params.ctype = 4
      }
      if (this.isInfo) {
        params.isInfo = true;
      }
      if (this.sevenChecked){
        params.expires=7
      }
      
      let signType = this.signType;
      this[`${signType}ErrorShow`] = false;
      this[`${signType}DisabledShow`] = false;
      this[`${signType}ErrorLogion`] = false;
      this.$refs[`${signType}Form`].validate(async (valid) => {
        if (valid) {
          this.$emit("getLoading", true);
          let result = await this.$store.dispatch(
                "API_Sign/ympSign_Login",
                params
            );
            if (result.success) {
              if(result.user_status == 1){
                this.$emit("successChange", result.data);
              }else{
                this.$alert('您的账户未激活，联系销售激活或咨询客服玉小漭-13761576547（微信同号）', '提示', {
                  confirmButtonText: '我知道了',
                  callback: (action) => {
                    this.signLoading = false;
                  },
                });
              }
            } else {
              if (
                  result.errorcode == 1002 ||

                  result.errorcode == 1004 ||
                  result.errorcode == 1014 ||
                  result.errorcode == 1024
              ) {
                this[`${signType}ErrorShow`] = true;
              }
              if (result.errorcode == 1001) {
                this.codeErrorShow2 = true
              }
              if (result.errorcode == 1012) {
                this[`${signType}ErrorLogion`] = true;
              }
              if (result.errorcode == 1003 || result.errorcode == 1005) {
                this.cetshow = true;
              }
              if (result.errorcode == 1013) {
                this[`${signType}DisabledShow`] = true;
              }
            }
            this.$emit("getLoading", false);
            this.signLoading = false;
        }
      });
    },
    async getNewActiveLink() {
      try {
        let result = await this.$store.dispatch(
            "baseStore/baseSign_getActiveMailRegain",
            {
              email: this.emailForm.email,
            }
        );
        if (result.success) {
          this.$osTip();
          this.cetshow = false;
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
    async getCode() {
      if (this.codeForm.phone == "") {
        this.$message.warning("请输入手机号");
        return;
      }
      if (parseInt(this.codeText) == this.codeText) {
        return;
      }
      try {
        let result = { success: true };
        await this.$store.dispatch("baseStore/getVerifyCode", {
          area_code: this.codeForm.areaCode.replace("+", ""),
          mobile: `${this.codeForm.phone}`,
          ctype: 4,
        });
        if (result.success) {
          this.$message.success("验证码发送成功");
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.codeText = TIME_COUNT;
            this.codeBtnDisabled = true;
            this.timer = setInterval(() => {
              if (this.codeText > 0 && this.codeText <= TIME_COUNT) {
                this.codeText--;
              } else {
                this.codeText = "获取验证码";
                clearInterval(this.timer);
                this.timer = null;
                this.codeBtnDisabled = false;
              }
            }, 1000);
          }
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
</script>
<style lang="less" scoped>
.sign {
  .signBtn {
    display: inline-block;
  }
  .custom_error_tip {
    color: #ff5155;
    font-size: 12px;
    line-height: 20px;
    a {
      color: #0083f6;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
/deep/.el-input--medium .el-input__inner {
  height: 40px;
  line-height: 40px;
}
.getCodeBtn {
  width: 112px;
  color: #91BE42;
  &:hover,
  &:focus {
    color: #91BE42;
    //border-color: #ffb9bb;
    //background-color: #fee;
  }
}
</style>